exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-suspend-jsx": () => import("./../../../src/pages/Contact-suspend.jsx" /* webpackChunkName: "component---src-pages-contact-suspend-jsx" */),
  "component---src-pages-develop-suspend-jsx": () => import("./../../../src/pages/Develop-suspend.jsx" /* webpackChunkName: "component---src-pages-develop-suspend-jsx" */),
  "component---src-pages-illastration-suspend-jsx": () => import("./../../../src/pages/Illastration-suspend.jsx" /* webpackChunkName: "component---src-pages-illastration-suspend-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-index-old-jsx": () => import("./../../../src/pages/index-old.jsx" /* webpackChunkName: "component---src-pages-index-old-jsx" */),
  "component---src-pages-photography-suspend-jsx": () => import("./../../../src/pages/Photography-suspend.jsx" /* webpackChunkName: "component---src-pages-photography-suspend-jsx" */),
  "component---src-pages-suriel-home-suspend-jsx": () => import("./../../../src/pages/SurielHome-suspend.jsx" /* webpackChunkName: "component---src-pages-suriel-home-suspend-jsx" */),
  "component---src-pages-test-mdx": () => import("./../../../src/pages/test.mdx" /* webpackChunkName: "component---src-pages-test-mdx" */),
  "component---src-templates-foto-directory-jsx": () => import("./../../../src/templates/FotoDirectory.jsx" /* webpackChunkName: "component---src-templates-foto-directory-jsx" */),
  "component---src-templates-foto-view-jsx": () => import("./../../../src/templates/FotoView.jsx" /* webpackChunkName: "component---src-templates-foto-view-jsx" */),
  "component---src-templates-post-jsx-content-file-path-blog-posts-a-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/blog/posts/a.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-blog-posts-a-mdx" */),
  "component---src-templates-post-jsx-content-file-path-blog-posts-b-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/blog/posts/b.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-blog-posts-b-mdx" */),
  "component---src-templates-post-jsx-content-file-path-blog-posts-xx-xx-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/blog/posts/xx/xx.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-blog-posts-xx-xx-mdx" */),
  "component---src-templates-posts-jsx": () => import("./../../../src/templates/posts.jsx" /* webpackChunkName: "component---src-templates-posts-jsx" */)
}

